'use client'

import { RiArrowGoBackLine } from '@remixicon/react'
import { Button } from 'antd'
import { useRouter } from 'next/navigation'

import Error404 from '@/assets/NotFound'

export default function NotFound() {
  const router = useRouter()

  return (
    <div className='flex min-h-screen w-screen flex-col items-center justify-center gap-6 sm:w-full'>
      <Error404 />
      <div className='flex flex-col items-center gap-2 text-center text-on-surface dark:text-dark-on-surface'>
        <div className='text-2xl'>We've lost this page</div>
        <span className='text-center text-lg'>
          Sorry, the page you are looking for doesn't exist or has been removed.
        </span>
      </div>
      <Button
        className='flex w-fit items-center bg-background hover:!bg-background/80 dark:bg-dark-background hover:dark:!bg-dark-background/80'
        onClick={router.back}
        icon={<RiArrowGoBackLine size={20} />}
      >
        Go back
      </Button>
    </div>
  )
}
